var exports = {};
exports = {
  A: {
    A: {
      "1": "E F A B",
      "8": "J D rB"
    },
    B: {
      "1": "C K L G M N O P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "33": "sB gB I i J D E F A B C K L G M N O j k l m n o p q r s tB uB"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "33": "I i J D E F"
    },
    E: {
      "1": "J D E F A B C K L G yB zB 0B 1B mB eB fB 2B 3B 4B nB oB 5B",
      "33": "I i xB lB"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 B C G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S 6B 7B 8B 9B eB pB AC fB",
      "2": "F"
    },
    G: {
      "1": "E CC DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "33": "lB BC qB"
    },
    H: {
      "1": "VC"
    },
    I: {
      "1": "I H ZC qB aC bC",
      "33": "gB WC XC YC"
    },
    J: {
      "1": "A",
      "33": "D"
    },
    K: {
      "1": "A B C U eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "cC"
    },
    P: {
      "1": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "1": "oC"
    },
    R: {
      "1": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 5,
  C: "CSS3 Box-sizing"
};
export default exports;